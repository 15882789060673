import React, { useEffect } from "react";
import logger from "../../../services/LoggingService";
// import mstrDashboardsConfig from "assets/configs/mstr-dashboards-config.json";
import * as common from "common";

// https://microstrategy.github.io/embedding-sdk-docs/

const NewLibraryPage = () => {
  const urlToUse = common.getMicroStrategyUrl();
  logger.error("urlToUse", urlToUse);

  useEffect(() => {
    window.microstrategy.embeddingContexts.embedLibraryPage(
      {
        serverUrl: "https://data-dev.pepfar.gov/MicroStrategyLibrary",
        placeholder: document.getElementById("LibraryHomePageContainer"),
      },
      []
    );
  });

  return (
    <>
      <div className="library-container" id="LibraryHomePageContainer"></div>
    </>
  );
};

export default NewLibraryPage;
