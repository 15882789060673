const sidebarItems = [
  {
    id: "Library",
    location: "sidebar",
    name: "Dashboard Library",
    desc: "",
    isActive: true,
    routePath: "",
    fontAwesomeIconClasses: "fas fa-lightbulb",
    externalLinkDevURL:
      "https://dev.pepfar-panorama.org/MicroStrategyLibrary/app?ui.toc=%7B%22dockedPosition%22%3A%22left%22%2C%22canClose%22%3A%20false%2C%22dockChangeable%22%3Afalse%2C%22isDocked%22%3A%20true%7D&app.onboard.visibleTutorials=0b0000",
    externalLinkProdURL:
      "https://spotlight.pepfar-panorama.org/MicroStrategyLibrary/app?ui.toc=%7B%22dockedPosition%22%3A%22left%22%2C%22canClose%22%3A%20false%2C%22dockChangeable%22%3Afalse%2C%22isDocked%22%3A%20true%7D&app.onboard.visibleTutorials=0b0000",
  },
  {
    id: "DAT",
    location: "sidebar",
    name: "Datasets",
    desc: "",
    isActive: true,
    routePath: "/datasets",
    fontAwesomeIconClasses: "fas fa-download",
  },
  {
    id: "LOGIN",
    location: "sidebar",
    name: "USG Login",
    desc: "",
    isActive: true,
    routePath: "",
    fontAwesomeIconClasses: "fas fa-key",
    externalLinkDevURL: "https://dev.pepfar-panorama.org/PEPFARLanding/",
    externalLinkProdURL: "https://pepfar-panorama.org/PEPFARLanding/",
  },
  {
    id: "EVAL",
    location: "sidebar",
    name: "Evaluations",
    desc: "",
    isActive: true,
    routePath: "/evaluations",
    fontAwesomeIconClasses: "fas fa-list-ul",
  },
  {
    id: "ADDL",
    location: "sidebar",
    name: "Additional Data",
    desc: "",
    isActive: true,
    routePath: "/additionalData",
    fontAwesomeIconClasses: "fas fa-chart-pie",
  },
  {
    id: "SRC",
    location: "sidebar",
    name: "Data Sources",
    desc: "",
    isActive: true,
    routePath: "/dataSources",
    fontAwesomeIconClasses: "fas fa-database",
  },
  {
    id: "HC-MAP",
    location: "sidebar",
    name: "Data Alignment",
    desc: "",
    isActive: true,
    routePath: "/daaMap1",
    fontAwesomeIconClasses: "fas fa-arrows-alt",
  },
  {
    id: "KC",
    location: "sidebar",
    name: "Knowledge Center",
    desc: "",
    isActive: true,
    routePath: "",
    fontAwesomeIconClasses: "fas fa-lightbulb",
    externalLinkDevURL: "https://dev.pepfar-panorama.org/knowledgecenter/",
    externalLinkProdURL: "https://pepfar-panorama.org/knowledgecenter/",
  },
  {
    id: "CAL",
    location: "sidebar",
    name: "Data Calendar",
    desc: "",
    isActive: true,
    routePath: "/calendar",
    fontAwesomeIconClasses: "far fa-calendar-alt",
  },
  {
    id: "FAQ",
    location: "sidebar",
    name: "F A Q's",
    desc: "",
    isActive: true,
    routePath: "/faqs",
    fontAwesomeIconClasses: "fas fa-question-circle",
  },
  {
    id: "GLO",
    location: "sidebar",
    name: "Glossary",
    desc: "",
    isActive: true,
    routePath: "/glossary",
    fontAwesomeIconClasses: "far fa-list-alt",
  },
];

export default sidebarItems;
