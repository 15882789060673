import React from "react";
import CollapsibleSection from "components/SecondaryPageContainer/CollapsibleSection/CollapsibleSection";
import ReactHtmlParser from "react-html-parser";
import Typography from "@mui/material/Typography";
import { v4 as uuid } from "uuid";
import "components/SecondaryPageContainer/SectionBuilder/SectionBuilder.scss";

const sectionBuilder = (props) => (
  <section>
    <Typography variant="h1" id="page-heading" role="heading" tabIndex={0}>
      {props.topLevelHeader}
    </Typography>
    {props.sectionTitles.map((sectionTitle, sectionTitleIndex) => (
      <div className="section-outer__div section" key={uuid()}>
        <Typography variant="h2" id={props.sectionIds[sectionTitleIndex]}>
          {sectionTitle}
        </Typography>

        {props.sectionDescriptions.has(sectionTitle) ? (
          <div>
            <Typography>
              {ReactHtmlParser(props.sectionDescriptions.get(sectionTitle))}
            </Typography>
          </div>
        ) : null}
        {props.jsonSectionsConfig.sections.map((currentSection) =>
          currentSection.sectionTitle === sectionTitle ? (
            <CollapsibleSection
              key={uuid()}
              isEOF={currentSection.eof === true}
              callback={props.callback}
              collapsibleSectionHeader={currentSection.subSectionHeader}
              collapsibleSectionParagraphs={currentSection.subSectionParagraphs}
            />
          ) : null
        )}
      </div>
    ))}
  </section>
);

export default sectionBuilder;
