import React, { useState } from "react";
import { MDBContainer, MDBRow, MDBSwitch } from "mdbreact";
import daaData from "./DaaMapGeoData.json";
import DaaMap, { displayLabels } from "./DaaMap";
import PropTypes from "prop-types";
import logger from "services/LoggingService";
import { useSelector, useDispatch } from "react-redux";
import { selectDaa, updateDaaState } from "stateMgmt/daaSlice";

import "./DAAMapPage.scss";

const DAAMapChart = (props) => {
  const currentState = useSelector(selectDaa);
  const dispatch = useDispatch();
  const [labelVisibility, setLabelVisibility] = useState(false);

  function toggleLabels(e) {
    logger.debug("DAAMapChart toggleLabels called");
    dispatch(
      updateDaaState({
        showLabels: e.target.checked,
      })
    );
    displayLabels(!labelVisibility);
    setLabelVisibility(!labelVisibility);
  }

  return (
    <div id="MAP-CHART" data-testid="MAP-CHART" className="map-div">
      <DaaMap mapFile={daaData} countries={currentState.labels} />
      <div>
        <MDBContainer fluid>
          <MDBRow data-testid="btnDiv" className="btnDiv" around>
            {currentState.buttonBar}
          </MDBRow>
        </MDBContainer>
      </div>
      <div className="controls">
        <div className="sliderDiv" data-testid="switch-div">
          <MDBSwitch
            tabIndex={-1}
            aria-disabled={true}
            labelLeft="Hide"
            labelRight="Show"
            checked={currentState.showLabels}
            onChange={(e) => toggleLabels(e)}
          />
          <span>Country Labels</span>
        </div>
      </div>
    </div>
  );
};

DAAMapChart.propTypes = {
  initialYear: PropTypes.string.isRequired,
};

export default DAAMapChart;
