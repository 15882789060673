import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { selectTheme } from "stateMgmt/complianceSlice";
import Footer from "components/Footer/Footer";
import ScrollToTopButton from "./ScrollToTopButton/ScrollToTopButton";
import jsonFooterConfig from "assets/configs/footer-config.json";
import { Typography } from "@mui/material";
import PropTypes from "prop-types";
import logger from "services/LoggingService";

import "./container.scss";

const secondaryPageContainer = (props) => {
  const theme = useSelector(selectTheme);
  const data = props.data;
  const [children, setChildren] = useState([]);

  useEffect(() => {
    logger.debug("Secondary Page: ", data);
    logger.debug(
      "Secondary Page Container rendered",
      document.getElementById("sec-hdr").innerHTML
    );
    if (data) {
      setChildren(data);
    }
    document.getElementById("sec-hdr").focus();
  }, [data]);

  let secondaryMainContentCssClasses =
    "secondary-page__main secondary-page__main__left-margin secondary-page__left-margin-wide-monitors";
  if (props.isSidebarCollapsed) {
    secondaryMainContentCssClasses = secondaryMainContentCssClasses.replace(
      "secondary-page__main__left-margin",
      ""
    );
    secondaryMainContentCssClasses = secondaryMainContentCssClasses.replace(
      "secondary-page__left-margin-wide-monitors",
      ""
    );
  }

  const goToPageStart = (e) => {
    if (e.keyCode === 32) {
      document.getElementById("page-heading").focus();
    }
  };

  return (
    <div
      id="mainContent"
      data-testid="secondary-page"
      className={secondaryMainContentCssClasses}
      style={{ backgroundColor: theme.backgroundColor, color: theme.color }}
      role="main"
      tabIndex={-1}
    >
      <div className="container-fluid secondary-page__container">
        <div>
          <nav className={props.showToc ? "secondary-toc" : "hidden"}>
            <Typography
              variant="h3"
              id="sec-hdr"
              tabIndex={0}
              onKeyDown={(e) => goToPageStart(e)}
            >
              ON THIS PAGE:
            </Typography>
            <ul
              id="toc"
              style={{
                color: theme.linkColor,
              }}
            >
              {children}
            </ul>
          </nav>
        </div>
        <div className="secondary-page-contents">
          <div className="l-panel l-panel--narrow">{props.contents}</div>
        </div>
      </div>
      <ScrollToTopButton scrollToTopLocation={props.pageHeaderId} />
      <Footer footerConfig={jsonFooterConfig} />
    </div>
  );
};

secondaryPageContainer.propTypes = {
  pageHeaderId: PropTypes.string.isRequired,
  isSidebarCollapsed: PropTypes.bool.isRequired,
  showToc: PropTypes.bool.isRequired,
  contents: PropTypes.any.isRequired,
};

export default secondaryPageContainer;
