import React, { useState, useEffect } from "react";
import { NavLink } from "react-router-dom";
import dosImage from "assets/images/dos_divider@2x.png";
import dosImageWebp from "assets/images/WEBP/dos_divider@2x.webp";
import WebpElement from "components/WebpElement/WebpElement";
import { useSelector } from "react-redux";
import { selectCompliance } from "stateMgmt/complianceSlice";
import { v4 as uuid } from "uuid";
// import logger from "services/LoggingService";

import "./Footer.scss";

const footer = (props) => {
  const appContext = useSelector(selectCompliance).context;
  const theme = useSelector(selectCompliance).theme;
  const [isHidden, setIsHidden] = useState(false);
  let footerRow1 = null;

  useEffect(() => {
    const hide = appContext !== "home";
    setIsHidden(hide);
  });

  footerRow1 = props.footerConfig.footerLinks.map((footerLink, index) =>
    typeof footerLink.internalRoutePath === "undefined" ? (
      <div
        className="col-md footer-links__mobile"
        aria-hidden={isHidden}
        key={uuid()}
      >
        <a
          target="_blank"
          style={{
            color: theme.color,
            fontWeight: 600,
          }}
          rel="noopener noreferrer"
          href={footerLink.href}
          aria-label={footerLink.desc}
          name={footerLink.name}
          tabIndex={isHidden ? -1 : 3}
        >
          {" "}
          {footerLink.name}
        </a>
      </div>
    ) : (
      <NavLink
        id={footerLink.targetid}
        className="col-md footer-links__mobile footer-router-navlink"
        tabIndex={isHidden ? -1 : 3}
        style={{
          color: theme.color,
          fontWeight: 600,
        }}
        key={uuid()}
        to={footerLink.internalRoutePath}
        aria-hidden={isHidden}
        exact
      >
        {footerLink.name}
      </NavLink>
    )
  );

  return (
    <div className="footer">
      <div className="container">
        <WebpElement
          className="img-fluid"
          data-testid="DOS-Seal"
          alt="Department of State seal"
          src={dosImage}
          webp={dosImageWebp}
          aria-hidden={true}
        />
        <div className="row" id="footer_row_1">
          {footerRow1}
        </div>
        <hr />

        {/* Leaving row_2_left and row_2_right here in case we need to add them back later */}
        <div className="row mt-2" id="footer_row_2">
          <div className="col-12" id="footer_row_2_middle">
            <a
              href="https://www.instagram.com/pepfar/"
              style={{
                color: theme.color,
              }}
              target="_blank"
              rel="noopener noreferrer"
              aria-label="open instagram"
              name="open instagram"
              tabIndex={isHidden ? -1 : 3}
            >
              <i className="fab fa-instagram"></i>
            </a>
            <a
              href="http://www.facebook.com/PEPFAR"
              style={{
                color: theme.color,
              }}
              target="_blank"
              tabIndex={isHidden ? -1 : 3}
              rel="noopener noreferrer"
              aria-label="open facebook"
              name="open facebook"
            >
              <i className="fab fa-facebook-square"></i>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default footer;
