import React from "react";
import PropTypes from "prop-types";

const setFocus = () => {
  const header = document.getElementById("page-header");
  if (header) {
    header.focus();
  }
};

const ScrollToTopButton = (props) => {
  setFocus();
  return (
    <a
      href={`#${props.scrollToTopLocation}`}
      aria-label="Scroll to top of page button"
    >
      <i className="fas fa-chevron-up scroll-to-top__button float-right"></i>
    </a>
  );
};

ScrollToTopButton.propTypes = {
  scrollToTopLocation: PropTypes.string,
};

export default ScrollToTopButton;
