import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import { styled } from "@mui/material/styles";

export const StyledHeaderCell = (align, color, backgroundColor) =>
  styled(TableCell)(() => ({
    [`&.${tableCellClasses.head}`]: {
      textAlign: "center",
      backgroundColor,
      color,
      fontSize: "1.1rem",
      padding: "5px 0px 0px 15px",
    },
    "&:nth-child(1)": {
      textAlign: align,
    },
  }));

export const StyledFirstRowCell = (align, color, backgroundColor) =>
  styled(TableCell)(() => ({
    [`&.${tableCellClasses.body}`]: {
      textAlign: align,
      fontSize: "0.9rem",
      fontWeight: 800,
      color,
      backgroundColor,
    },
    "&:nth-child(1)": {
      textAlign: "left",
    },
  }));

export const StyledTableCell = (align, color) =>
  styled(TableCell)(() => ({
    [`&.${tableCellClasses.body}`]: {
      textAlign: align,
      fontSize: "0.7rem",
      fontWeight: 800,
      color,
    },
    "&:nth-child(1)": {
      textAlign: "left",
    },
  }));

export const StripedTableRow = (evenColor, oddColor) =>
  styled(TableRow)(() => ({
    [`&.${tableCellClasses.body}`]: {},
    "&:nth-of-type(odd)": {
      backgroundColor: oddColor,
    },
    "&:nth-of-type(even)": {
      backgroundColor: evenColor,
    },
    // hide last border
    "&:last-child td, &:last-child th": {
      border: 0,
    },
  }));
