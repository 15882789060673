import React, { useState, useEffect } from "react";
import { NavLink } from "react-router-dom";
import { MDBIcon } from "mdbreact";
import "./Header.scss";
import pepfarHeaderLogo from "assets/images/US-PEPFAR-Logo.png";
import pepfarHeaderLogoWebp from "assets/images/WEBP/US-PEPFAR-Logo.webp";
import { useSelector, useDispatch } from "react-redux";
import { selectCompliance, toggleTheme } from "stateMgmt/complianceSlice";

// import logger from "services/LoggingService";

import PropTypes from "prop-types";

const Header = (props) => {
  const appContext = useSelector(selectCompliance).context;
  const theme = useSelector(selectCompliance).theme;
  const dispatch = useDispatch();
  const [isDark, setMode] = useState(false);
  const [isHidden, setIsHidden] = useState(false);
  const toggleMode = () => {
    dispatch(toggleTheme({}));
    const newMode = !isDark;
    setMode(newMode);
  };

  useEffect(() => {
    const mode = localStorage.getItem("dark") === "true";
    setMode(mode);
    const hide = appContext !== "home";
    setIsHidden(hide);
  });

  return (
    <header>
      <div className="header-container">
        <nav
          className="flex-md-nowrap p-0 nav-style"
          style={{ borderBottom: theme.headerBorder }}
          aria-hidden={isHidden}
          // tabIndex={isHidden ? -1 : 0}
        >
          <NavLink
            data-testid="logo"
            className="logo-anchor"
            to="/"
            aria-label="United States Presidents emergency plan for AIDS relief logo. "
            exact
          >
            <picture>
              <source srcSet={pepfarHeaderLogoWebp} type="image/webp" />
              <source srcSet={pepfarHeaderLogo} type="image/png" />
              <img src={pepfarHeaderLogo} alt="PEPFAR Logo" />
            </picture>
            <span
              id="site-start"
              role="heading"
              className="header__title-text"
              tabIndex={isHidden ? -1 : 1}
            >
              PEPFAR Panorama Spotlight
            </span>
          </NavLink>
          <span
            className="header__dropdown-menu"
            onClick={props.dropDownMenuClickHandler}
            tabIndex={-1}
          >
            Menu
          </span>
          <div
            data-testid="light-btn"
            className={isDark ? "modeDiv" : "hidden"}
          >
            <MDBIcon
              data-testid="light-mode"
              aria-label="Turn off dark mode"
              fas
              icon="moon"
              onClick={() => toggleMode()}
            />
          </div>
          <div data-testid="dark-btn" className={isDark ? "hidden" : "modeDiv"}>
            <MDBIcon
              data-testid="dark-mode"
              aria-label="Turn on dark mode"
              far
              icon="moon"
              onClick={() => toggleMode()}
            />
          </div>
        </nav>
      </div>
    </header>
  );
};

Header.propTypes = {
  dropDownMenuClickHandler: PropTypes.func,
};

export default Header;
