import React, { useRef } from "react";
import { useSelector } from "react-redux";
import { selectCompliance } from "stateMgmt/complianceSlice";

import img1 from "assets/images/CountryStories/cam-1-img1.png";
import img2 from "assets/images/CountryStories/cam-1-img2.png";
import img3 from "assets/images/CountryStories/cam-1-img3.png";
import redArrow from "assets/images/right-arrow-red.png";
import blueArrow from "assets/images/right-arrow-blue.png";

import "./CountryPages.scss";

const CameroonPage1 = () => {
  const theme = useSelector(selectCompliance).theme;
  const pageTitle = useRef(null);

  return (
    <div
      role="dialog"
      name="camP1"
      aria-label="Cameroon Page 1"
      id="camP1"
      className="flipstory-container"
      style={{ backgroundColor: theme.backgroundColor, color: theme.color }}
    >
      <header
        className="story-title"
        ref={pageTitle}
        tabIndex={0}
        name="title"
        role="note"
      >
        Cameroon’s Data Reporting Systems ​Pre & Post PEPFAR​
      </header>
      <section className="story-content">
        <div className="main-row">
          <div>
            <figure>
              <img
                name="img1"
                role="img"
                src={img1}
                className="mainImg"
                alt="Pre Pepfar era. All paper based tools and storage."
                tabIndex={0}
              />
            </figure>
          </div>
          <div className="arrowDiv">
            <img src={blueArrow} className="arrow" alt="transitions to" />
          </div>

          <div>
            <figure>
              <img
                name="img2"
                role="img"
                src={img2}
                className="mainImg"
                alt="2011 Pepfar Intervention. Electronic systems in use."
                tabIndex={0}
              />
            </figure>
          </div>
          <div className="arrowDiv">
            <img src={redArrow} className="arrow" alt="transitions to" />
          </div>

          <div>
            <figure>
              <img
                name="img3"
                role="img"
                src={img3}
                className="mainImg"
                alt="Current state. Digital health strategic plan in place."
                tabIndex={0}
              />
            </figure>
          </div>
        </div>
      </section>
    </div>
  );
};

export default CameroonPage1;
