 

import React, { useRef } from "react";
import { useSelector } from "react-redux";
import { selectCompliance } from "stateMgmt/complianceSlice";

import laptop from "assets/images/CountryStories/carLaptop.png";

import "./CountryPages.scss";

const CaribbeanPage1 = () => {
  const theme = useSelector(selectCompliance).theme;
  const pageTitle = useRef(null);

  return (
    <div
      role="dialog"
      aria-label="Caribbean Page 1"
      id="carP1"
      name="carP1"
      className="flipstory-container"
      style={{ backgroundColor: theme.backgroundColor, color: theme.color }}
    >
      <header
        className="story-title"
        ref={pageTitle}
        tabIndex={0}
        name="title"
        role="note"
      >
        Strengthening the Treatment Site Information System, Jamaica, 2019-2023​
      </header>
      <section className="story-content">
        <div className="main-row">
          <div className="row1">
            <div tabIndex={0} name="primary" role="note">
              <h1>
                Primary Limitations of the Treatment Site Information System
                (TSIS), 2018 ​
              </h1>
              <ul className="key-list">
                <li>User interface lacks customization</li>
                <li>Inflexible data structure</li>
                <li>No role-based access controls</li>
                <li>Needed third party data tools</li>
                <li>Lack of support</li>
              </ul>
            </div>
            <div tabIndex={0} name="proposed" role="note">
              <h1>Proposed solution​</h1>
              <h3>
                Complete development of HIV-specific EMR using open-source tools
                to address the limitations of DHIS2-based platform. Key
                improvements include:​
              </h3>
              <div>
                <ul className="key-list-2">
                  <li>Clean User Interface</li>
                  <li>Role-based access controls​</li>
                  <li>Enterprise-grade reporting​</li>
                  <li>Modular and Customizable​</li>
                  <li>Standard data structure</li>
                </ul>
              </div>
            </div>
          </div>
          <h2>Treatment Site Information System 2.0, TSIS2, 2023​</h2>
          <div className="row2" tabIndex={0} name="row2" role="note">
            <div>
              <img src={laptop} />
            </div>
            <div className="keys">
              <h1>KEY ACHIEVEMENTS</h1>
              <ul className="key-list-3">
                <li>Improved national estimates of persons linked to care</li>
                <li>Improved national estimate of 2nd and 3rd 95​</li>
                <li>
                  Improved data for program management at site, parish, regional
                  and national levels​
                </li>
              </ul>
            </div>
          </div>
          <div className="footer" tabIndex={0} name="footer" role="note">
            This activity was supported by the President&apos;s Emergency Plan
            for AIDS Relief (PEPFAR) through the U.S. Centers or Disease Control
            and Prevention (CDC) and the implementing partner University of
            California, San Francisco (UCSF)​
          </div>
        </div>
      </section>
    </div>
  );
};

export default CaribbeanPage1;
