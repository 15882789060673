import React, { useEffect } from "react";
import "./ContactUsPage.scss";
import SecondaryPageContainer from "components/SecondaryPageContainer/NewSecondaryPageContainer";
import { NavLink } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { selectTheme } from "stateMgmt/complianceSlice";
import { updateFooterLink } from "stateMgmt/daaSlice";
import { Typography } from "@mui/material";
import PropTypes from "prop-types";
import ReactGA from "react-ga4";
import logger from "services/LoggingService";

const ContactUsPage = (props) => {
  const dispatch = useDispatch();
  const theme = useSelector(selectTheme);

  ReactGA.send({
    hitType: "pageview",
    page: "/components/MainContentArea/ContactUsPage/ContactUsPage.js",
    title: "ContactUs",
  });

  useEffect(() => {
    document.getElementById("ContactUs_page-top").focus();
  }, []);

  const leavePage = () => {
    logger.debug("  leaving disclaimer page");
    dispatch(updateFooterLink({ calllingLink: "Contact" }));
  };

  const pageContents = (
    <div
      className="row"
      id="ContactUs_page-top"
      data-testid="ContactUs_Page"
      tabIndex={0}
    >
      <div className="col-lg-3" role="note" tabIndex={0}>
        <Typography variant="h3">
          Stay Connected <br></br> with PEPFAR
        </Typography>
        <ul className="c-social__list" role="navigation">
          <li className="c-social__item ">
            <a
              style={{ color: theme.linkColor, fontWeight: 800 }}
              className="c-social__link"
              href="http://www.facebook.com/PEPFAR"
              target="_blank"
              rel="noopener noreferrer"
              aria-label="facebook"
            >
              <i className="fab fa-facebook-square"></i>
            </a>
          </li>
          <li className="c-social__item ">
            <a
              style={{ color: theme.linkColor, fontWeight: 800 }}
              className="c-social__link"
              href="http://www.flickr.com/photos/pepfar"
              target="_blank"
              rel="noopener noreferrer"
              aria-label="flickr"
            >
              <i className="fab fa-flickr"></i>
            </a>
          </li>
          <li className="c-social__item ">
            <a
              style={{ color: theme.linkColor, fontWeight: 800 }}
              className="c-social__link"
              href="http://www.youtube.com/user/uspepfar"
              target="_blank"
              rel="noopener noreferrer"
              aria-label="youtube"
            >
              <i className="fab fa-youtube-square"></i>
            </a>
          </li>
          <li className="c-social__item ">
            <a
              style={{ color: theme.linkColor, fontWeight: 800 }}
              className="c-social__link"
              href="http://www.state.gov/rss/channels/ihi.xml"
              target="_blank"
              rel="noopener noreferrer"
              aria-label="rss feed"
            >
              <i className="fas fa-rss-square"></i>
            </a>
          </li>
          <li className="c-social__item ">
            <a
              style={{ color: theme.linkColor, fontWeight: 800 }}
              className="c-social__link"
              href="https://service.govdelivery.com/accounts/USSTATEBPA/subscriber/new?topic_id=USSTATEBPA_24"
              target="_blank"
              rel="noopener noreferrer"
              aria-label="email"
            >
              <i className="fas fa-envelope-square"></i>
            </a>
          </li>
        </ul>

        <div role="note" tabIndex={0}>
          <p>
            Stay up to date with our{" "}
            <a
              style={{ color: theme.linkColor, fontWeight: 800 }}
              href="https://blogs.state.gov/"
              target="_blank"
              rel="noopener noreferrer"
              aria-label="blog"
            >
              Blog
            </a>
          </p>
        </div>
      </div>

      <div className="col-lg-7">
        <div role="note" tabIndex={0}>
          <h1>Contact Us</h1>
          <p>
            We hope you will take this opportunity to share your thoughts about
            this site with us! Please{" "}
            <a
              style={{ color: theme.linkColor, fontWeight: 800 }}
              href="mailto:SGACPublicAffairs@state.gov?subject=Question/Comment%20from%20PEPFAR%20Dashboard"
              className="link"
              aria-label="email"
            >
              email us
            </a>{" "}
            or call us at 202-663-2708 with feedback or to report any issues
            with the site, but note that before contacting us, you may be able
            to find the answer to your questions in our{" "}
            <NavLink
              to="/faqs"
              style={{ color: theme.linkColor, fontWeight: 800 }}
            >
              Frequently Asked Questions
            </NavLink>
            .
          </p>
          <p>
            For information about PEPFAR in general, please visit{" "}
            <a
              style={{ color: theme.linkColor, fontWeight: 800 }}
              href="https://www.state.gov/pepfar/"
              className="link"
              target="_blank"
              rel="noopener noreferrer"
            >
              state.gov/PEPFAR
            </a>
            . To view restricted public data not available on PEPFAR Panorama
            Spotlight, please complete{" "}
            <a
              style={{ color: theme.linkColor, fontWeight: 800 }}
              href="https://pepfar-panorama.zendesk.com/hc/en-us/requests/new?ticket_form_id=360000273751"
              target="_blank"
              rel="noopener noreferrer"
              className="link"
              aria-label="foreign assistance site"
            >
              this form
            </a>
            . To view other U.S. foreign assistance data, please continue{" "}
            <a
              style={{ color: theme.linkColor, fontWeight: 800 }}
              href="http://www.foreignassistance.gov"
              target="_blank"
              rel="noopener noreferrer"
              className="link"
              aria-label="foreign assistance site"
            >
              here
            </a>
            .
          </p>
          <p>
            To submit feedback on the current MER Guidance, please click{" "}
            <a
              style={{ color: theme.linkColor, fontWeight: 800 }}
              href=" https://pepfar-panorama.zendesk.com/hc/en-us/requests/new?ticket_form_id=7242960312084"
              target="_blank"
              rel="noopener noreferrer"
            >
              here.
            </a>
          </p>
        </div>

        <form name="dummy-form-for-onblur">
          <p role="note" tabIndex={0}>
            For questions about the federal government not related to PEPFAR,
            visit{" "}
            <a
              style={{ color: theme.linkColor, fontWeight: 800 }}
              href="http://www.usa.gov"
              className="link"
              target="_blank"
              rel="noopener noreferrer"
              aria-label="Federal governement site"
              tabIndex={0}
              onBlur={() => leavePage()}
            >
              USA.gov
            </a>{" "}
            or call 1(800) FED INFO (1-800-333-4636) (8am - 8pm ET Monday -
            Friday).
          </p>
        </form>
      </div>
    </div>
  );

  return (
    <SecondaryPageContainer
      contents={pageContents}
      showToc={false}
      pageHeaderId="ContactUs_page-top"
      isSidebarCollapsed={props.isSidebarCollapsed}
    />
  );
};

ContactUsPage.propTypes = {
  isSidebarCollapsed: PropTypes.bool.isRequired,
};

export default ContactUsPage;
