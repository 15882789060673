import { createSlice } from "@reduxjs/toolkit";
import daaData from "components/DAAMapPage/DaaMapGeoData.json";
import sideBar from "assets/data/sidebarItems";
import footer from "assets/configs/footer-config";
import logger from "services/LoggingService";

const initialState = {
  displayedYear: "FY2021",
  fyYearIndex: 0,
  fiscalYears: ["FY2021", "FY2022"],
  currentIndicatorIndex: 0,
  currentIndicator: "TX_CURR",
  currentTableData: [],
  btnSelected: 0,
  buttonBar: [],
  sortRow: 0,
  showLabels: false,
  trackedIndicators: daaData.trackedIndicators[0],
  countries: [],
  labels: [],
  sidebarLinks: [],
  footerLinks: [],
  currentNavLink: "",
  currentFooterLink: "",
};

const getCountries = () => {
  const tmpCountries = [];
  const tmpLabels = [];

  daaData.features.forEach((country) => {
    if (country.properties.tracked) {
      const value = country.properties.name;
      const text = country.properties.name;
      tmpCountries.push({ text, value });
      tmpLabels.push(country.properties.name);
    }
  });

  return { countries: tmpCountries, labels: tmpLabels };
};

export const daaSlice = createSlice({
  name: "daaState",
  initialState,
  reducers: {
    initDaa: (state, action) => {
      const countriesBuffer = getCountries();
      const sidebarBuffer = sideBar.map((link) => link.id);
      const footerTmp = footer.footerLinks.filter(
        (link) => link.targetid !== null
      );
      const footerBuffer = footerTmp.map((link) => link.targetid);

      const newState = {
        ...state,
        countries: [...countriesBuffer.countries],
        labels: [...countriesBuffer.labels],
        sidebarLinks: [...sidebarBuffer],
        footerLinks: [...footerBuffer],
      };
      return newState;
    },
    updateDaaState: (state, action) => {
      const newState = {
        ...state,
        ...action.payload,
      };
      return newState;
    },
    updateNavLink: (state, action) => {
      const index = state.sidebarLinks.indexOf(action.payload.calllingLink);

      const nextLink =
        index >= 0 && index < state.sidebarLinks.length - 1
          ? state.sidebarLinks[index + 1]
          : "site-start";

      const newState = {
        ...state,
        currentNavLink: nextLink,
      };
      return newState;
    },
    updateFooterLink: (state, action) => {
      const index = state.footerLinks.indexOf(action.payload.calllingLink);
      logger.error(
        `Footer calling link: ${action.payload.calllingLink} footer index: ${index}`
      );
      const nextLink =
        index >= 0 && index < state.footerLinks.length - 1
          ? state.footerLinks[index + 1]
          : "site-start";

      const newState = {
        ...state,
        currentFooterLink: nextLink,
      };
      return newState;
    },
  },
});

export const selectDaa = (state) => state.daaState;
export const selectNavLink = (state) => state.daaState.currentNavLink;
export const selectFooterLink = (state) => state.daaState.currentFooterLink;
export const { initDaa, updateDaaState, updateNavLink, updateFooterLink } =
  daaSlice.actions;
export default daaSlice.reducer;
