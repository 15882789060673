import React from "react";
import SecondaryPageContainer from "components/SecondaryPageContainer/NewSecondaryPageContainer";
import ReactGA from "react-ga4";

const http404NotFoundPage = (props) => {
  ReactGA.send({
    hitType: "pageview",
    page: "/components/MainContentArea/Http404NotFoundPage/Http404NotFoundPage.js",
    title: "404 Not Found",
  });

  const pageContents = (
    <div className="row" id="http404NotFoundPage-top">
      <div className="col-lg-12 text-center">
        <h1>The page you are looking for cannot be found</h1>
        <h4>Error Code: 404</h4>
      </div>
    </div>
  );

  return (
    <SecondaryPageContainer
      contents={pageContents}
      showToc={false}
      pageHeaderId=""
      isSidebarCollapsed={props.isSidebarCollapsed}
    />
  );
};

export default http404NotFoundPage;
