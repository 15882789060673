import React from "react";
import { createTheme } from "@mui/material/styles";
import { Typography } from "@mui/material";
import { v4 as uuid } from "uuid";

const theme = createTheme({
  typography: {
    htmlFontSize: 6,
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    fontWeight: 600,
    marginBottom: ".5rem",
    lineHeight: 1.2,
  },
});

theme.typography.h1 = {
  paddingLeft: "10px",
  fontSize: "1.3rem",
  fontWeight: 800,
  "@media (min-width:600px)": {
    fontSize: "1.1rem",
  },
  [theme.breakpoints.up("md")]: {
    fontSize: "2.5rem",
  },
};

theme.typography.h2 = {
  paddingTop: "30px",
  paddingLeft: "10px",
  fontSize: "0.8rem",
  fontWeight: 700,
  "@media (min-width:600px)": {
    fontSize: "0.8rem",
  },
  [theme.breakpoints.up("md")]: {
    fontSize: "1.5rem",
  },
};

theme.typography.h3 = {
  paddingLeft: "10px",
  fontSize: "0.4rem",
  fontWeight: 700,

  "@media (min-width:600px)": {
    fontSize: "0.5rem",
  },
  [theme.breakpoints.up("md")]: {
    fontSize: "0.9rem",
  },
};

theme.typography.h6 = {
  paddingLeft: "10px",
  fontSize: "0.1rem",
  fontWeight: 700,
  "@media (min-width:600px)": {
    fontSize: "0.2rem",
  },
  [theme.breakpoints.up("md")]: {
    fontSize: "0.3rem",
  },
};

theme.typography.body1 = {
  fontSize: "0.4rem",
  paddingTop: "15px",
  paddingLeft: "30px",

  "@media (min-width:600px)": {
    fontSize: "0.5rem",
    // color: "green",
  },
  [theme.breakpoints.up("md")]: {
    fontSize: "0.9rem",
    // color: "red",
  },
};

theme.typography.body2 = {
  fontSize: "0.4rem",
  paddingLeft: "5px",
  "@media (min-width:600px)": {
    fontSize: "0.5rem",
    // color: "green",
  },
  [theme.breakpoints.up("md")]: {
    fontSize: "0.8rem",
    // color: "red",
  },
};

export const getFontTheme = () => {
  return theme;
};

export const createThemeLink = (url, text, blurFunc) => {
  return url.includes("http") ? (
    <Typography>
      <a
        key={uuid()}
        style={{ color: theme.linkColor, fontWeight: 800 }}
        href={url}
        rel="noopener noreferrer"
        target="_blank"
      >
        {text}
      </a>
    </Typography>
  ) : (
    <Typography>
      <a
        key={uuid()}
        style={{ color: theme.linkColor, fontWeight: 800 }}
        href={url}
        onBlur={blurFunc}
      >
        {text}
      </a>
    </Typography>
  );
};
