import React, { useEffect, useState } from "react";
import faqsSectionsConfig from "assets/configs/faqs-sections-config.json";
import SecondaryPageContainer from "components/SecondaryPageContainer/NewSecondaryPageContainer";
import SectionBuilder from "components/SecondaryPageContainer/SectionBuilder/SectionBuilder";
import { useDispatch } from "react-redux";
import { updateNavLink } from "stateMgmt/daaSlice";
import { createThemeLink } from "services/FontTheme";
import ReactGA from "react-ga4";
import PropTypes from "prop-types";

const FAQsPage = (props) => {
  ReactGA.send({
    hitType: "pageview",
    page: "/components/MainContentArea/FAQsPage/FAQsPage.js",
    title: "FAQs",
  });
  const dispatch = useDispatch();
  const [tocData, setTocData] = useState([]);
  const uniqueSectionTitles = getUniqueSectionTitles();
  const uniqueSectionIds = generateSectionIds(uniqueSectionTitles);
  const sectionDescriptionsMap = getSectionDescriptions();

  const leavePage = () => {
    dispatch(updateNavLink({ calllingLink: "FAQ" }));
  };

  useEffect(() => {
    renderTOC();
  }, []);

  const pageContents = (
    <div
      className="row"
      id="FAQs_page-top"
      data-testid="FAQs_Page"
      tabIndex={0}
      // onBlur={() => leavePage()}
    >
      <div className="text-left">
        <SectionBuilder
          topLevelHeader="Frequently Asked Questions"
          sectionTitles={Array.from(uniqueSectionTitles)}
          sectionDescriptions={sectionDescriptionsMap}
          sectionIds={Array.from(uniqueSectionIds)}
          jsonSectionsConfig={faqsSectionsConfig}
          callback={leavePage}
        />
      </div>
    </div>
  );

  function renderTOC() {
    const links = [];
    const sectionTitles = Array.from(getUniqueSectionTitles());
    const sectionIds = Array.from(uniqueSectionIds);

    sectionTitles.forEach((sectionTitle, index) => {
      const title = sectionTitle;
      const link = `#${sectionIds[index]}`;

      links.push(createThemeLink(link, title));
    });

    setTocData(links);
  }

  return (
    <SecondaryPageContainer
      contents={pageContents}
      showToc={true}
      pageHeaderId="page-heading"
      isSidebarCollapsed={props.isSidebarCollapsed}
      data={tocData}
    />
  );
};

function getUniqueSectionTitles() {
  const uniqueSectionTitles = new Set();
  faqsSectionsConfig.sections.map((section, _) =>
    uniqueSectionTitles.add(section.sectionTitle)
  );
  return uniqueSectionTitles;
}

function getSectionDescriptions() {
  const sectionDescriptionsMap = new Map();
  faqsSectionsConfig.sections.map((section, _) =>
    section.sectionDescription !== undefined
      ? sectionDescriptionsMap.set(
          section.sectionTitle,
          section.sectionDescription
        )
      : null
  );
  return sectionDescriptionsMap;
}

function generateSectionIds(sectionTitles) {
  const generatedSectionIds = new Set();

  const iterator = sectionTitles.entries();
  for (const entry of iterator) {
    const splitString = entry[0].split(" ");

    let newSectionId = "";
    for (let i = 0; i < splitString.length; i++) {
      newSectionId += splitString[i] + "_";
    }
    generatedSectionIds.add(newSectionId);
  }
  return generatedSectionIds;
}

FAQsPage.propTypes = {
  isSidebarCollapsed: PropTypes.bool.isRequired,
};

export default FAQsPage;
