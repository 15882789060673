import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import SecondaryPageContainer from "components/SecondaryPageContainer/NewSecondaryPageContainer";
import * as common from "common";
import { useSelector, useDispatch } from "react-redux";
import { selectTheme } from "stateMgmt/complianceSlice";
import { updateNavLink } from "stateMgmt/daaSlice";
import { Typography } from "@mui/material";
import { createThemeLink } from "services/FontTheme";
import PropTypes from "prop-types";
import ReactGA from "react-ga4";
import "./additional-data.scss";
import logger from "services/LoggingService";

const S3Path = common.getS3Url();

const AdditionalDataPage = (props) => {
  const dispatch = useDispatch();
  const [tocData, setTocData] = useState([]);
  const theme = useSelector(selectTheme);

  ReactGA.send({
    hitType: "pageview",
    page: "/components/MainContentArea/AdditionalDataPage/AdditionalDataPage.js",
    title: "Additional Data",
  });

  const leavePage = () => {
    dispatch(updateNavLink({ calllingLink: "ADDL" }));
  };

  const pageContents = (
    <div
      className="row additional"
      id="AdditionalData_page-top"
      data-testid="AdditionalData_Page"
    >
      <div className="text-left">
        <Typography variant="h1" role="heading" tabIndex={0}>
          Additional Data
        </Typography>

        <section className="section" tabIndex={0} id="overview">
          <Typography variant="h2" role="header">
            Overview
          </Typography>
          <Typography>
            PEPFAR data can be analyzed using a variety of data sources. A
            variety of historical PEPFAR monitoring data and sub-national data
            files have been compiled and are available for download and review
            on this page. International Aid Transparency Initiative (IATI) files
            help ensure the U.S. Government meets its commitments under IATI to
            publish data in a common, open format. Organization and Activity
            level IATI files are available. Please see the links below for
            additional details or to access any of these data sets.
          </Typography>
        </section>

        <section className="section" tabIndex={0} id="historical-data">
          <Typography variant="h2" role="header">
            Historical Data
          </Typography>
          <Typography>
            Historical datasets and their associated MER Guidance documents are
            provided in this section. A 2017+ dataset is provided to allow
            trending of PEPFAR data from 2004 to the most recently completed
            Fiscal Year. When working with and trending PEPFAR historical data
            it is important to consider that MER Indicator definitions and
            reporting has evolved over time. Historical MER Guidance documents
            are provided for reference of changes over time, more recent MER{" "}
            <a
              style={{ color: theme.linkColor, fontWeight: 800 }}
              href="https://datim.zendesk.com/hc/en-us/articles/360000084446-MER-Indicator-Reference-Guides"
              target="_blank"
              rel="noopener noreferrer"
            >
              Guidance documents can be accessed here.
            </a>
          </Typography>
        </section>

        <div role="navigation">
          <Typography>
            <a
              style={{ color: theme.linkColor, fontWeight: 800 }}
              href={`${S3Path}mer_downloadables/Indicator-Reference-Guides-2004-2016.zip`}
            >
              Indicator Reference Guides 2004 - 2016.
            </a>
          </Typography>
          <Typography>
            <a
              style={{ color: theme.linkColor, fontWeight: 800 }}
              href={`${S3Path}mer_downloadables/Sub-National.Program.Results.2015-2016-all.zip`}
            >
              Sub-National Program Results 2015-2016.
            </a>
          </Typography>
          <Typography>
            <a
              style={{ color: theme.linkColor, fontWeight: 800 }}
              href={`${S3Path}mer_downloadables/Country.and.Regional.Targets_Results.2004-2016-all.zip`}
            >
              Country and Regional Targets and Results 2004-2016.
            </a>
          </Typography>
          <Typography>
            <a
              style={{ color: theme.linkColor, fontWeight: 800 }}
              href={`${S3Path}additionalData/partner-mer-data/Country_and_Regional_Targets_and_Results_2017_plus.zip`}
            >
              Country and Regional Targets and Results 2017+.
            </a>
          </Typography>
        </div>

        <section className="section" tabIndex={0} id="subnational-data">
          <Typography variant="h2" role="header">
            Sub-National Data
          </Typography>

          <Typography>
            The Sub-National Program Results 2015-2016 file contains PEPFAR data
            by country, disaggregated to the provincial level. If you would like
            to view SNU data at the district level, please refer to the
            Dashboard Library, Geographical Analysis dashboard, Sub-National
            Unit page
            <NavLink
              to="/library"
              style={{ color: theme.linkColor, fontWeight: 800 }}
            >
              {" "}
              here
            </NavLink>
            .
          </Typography>
        </section>

        <section className="section" tabIndex={0} id="iati-activity-data">
          <Typography variant="h2" role="header">
            PEPFAR IATI Activity
          </Typography>
          <Typography>
            In prior years, PEPFAR directly participated in the International
            Aid Transparency Initiative (IATI) by submitting data periodically
            to the IATI registry. The submissions were done on a yearly basis in
            alignment with the Publish What You Fund (PWYF) index activity, from
            Q2 2020 through Q4 2022. In Q1 2023, the U.S. government changed the
            way it generates data for submission to the IATI registry. A result
            of this change is that PEPFAR can no longer submit data directly to
            the IATI registry. In an effort to continue to increase the
            transparency and accessibility of PEPFAR data, PEPFAR will begin
            posting, as of Q3 2023, an IATI-type of file to PEPFAR Spotlight for
            public use.
          </Typography>
        </section>
        <div role="navigation">
          <Typography>
            <a
              href={`${S3Path}additionalData/iati_activity/PEPFAR_IATI_Activity_File.zip`}
              target="_blank"
              rel="noopener noreferrer"
              style={{ color: theme.linkColor, fontWeight: 800 }}
            >
              PEPFAR IATI Activity File
            </a>
          </Typography>
          <Typography>
            <a
              href={`${S3Path}additionalData/iati_activity/PEPFAR.IATI.Activity.File.Users.Guide.pdf`}
              style={{ color: theme.linkColor, fontWeight: 800 }}
            >
              PEPFAR IATI Activity File Users Guide
            </a>
          </Typography>
        </div>

        <section className="section" tabIndex={0} id="partner-budget-data">
          <Typography variant="h2" role="header">
            Partner Budget Dataset - Budget Code Format
          </Typography>
          <Typography>
            This dataset contains mechanism-level COP budget data for
            COP17-COP21 using budget codes. Budget codes were used for the last
            COP17-COP21 using budget codes. Budget codes were used for the last
            time in COP21 and replaced by the PEPFAR Financial Classifications,
            which is why the budget code data in this data set is only shown
            through COP21 and will no longer be updated.
          </Typography>
          <Typography>
            In this dataset, new funding amounts in each row are displayed in
            total and then arrayed to several different categories. The new
            funding total for a mechanism is displayed in the &quot;total new
            funding sources&quot; column. This total new funding is then arrayed
            to the budget codes, the program areas, and the funding accounts
            (GAP, GHP-State and GHP-USAID) such that the total new funding for
            each mechanism should equal the sum of the budget codes, the sum of
            the program areas, and the sum of the three funding accounts. Put
            differently, the budget codes, program areas and three funding
            accounts are each a different way to array new funding, and thus
            each of them should sum to equal the new funding total.
          </Typography>

          <Typography role="note" className="rules">
            Rules to produce this dataset:
          </Typography>
          <div role="navigation">
            <Typography>
              <ul>
                <li>
                  <Typography variant="body2">
                    Budget amounts are aggregated to the mechanism ID and
                    planning year level.
                  </Typography>
                </li>
                <li>
                  <Typography variant="body2">
                    For any TBD partners, some mechanism details are redacted
                    and budgets are aggregated to the OU by agency level such
                    that the amount of each TBD mechanism is not known.
                  </Typography>
                </li>
                <li>
                  <Typography variant="body2">
                    Dataset &quot;
                    <b>Public_Partner_Budget_by_Budget_Code.txt</b>
                    &quot; in the .zip file below contains budget data from
                    COP17 to COP21.
                  </Typography>
                </li>
              </ul>
            </Typography>
          </div>
          <Typography>
            Operating Units and Countries were updated to be consistent with
            expenditure data.
          </Typography>
        </section>
        <div role="navigation">
          <Typography>
            <a
              style={{ color: theme.linkColor, fontWeight: 800 }}
              href={`${S3Path}additionalData/partner-budget-data/Public_Partner_Budget_by_Budget_Code.zip`}
            >
              Public_Partner_Budget_by_Budget_Code.zip
            </a>
          </Typography>
        </div>

        <section className="section" tabIndex={0} id="partner-budget-financial">
          <Typography variant="h2" role="header">
            Partner Budget Dataset – Financial Classifications Format
          </Typography>

          <Typography>
            This dataset contains mechanism-level COP budget data, which is
            structured and categorized using the PEPFAR Financial
            Classifications. The PEPFAR Financial Classifications were
            introduced into the budgeting process in COP19, and were fully
            incorporated in COP20, which is why this dataset begins in COP20.
            The COP budget represents the total resources (regardless of whether
            they are new resources or prior year applied pipeline resources)
            that a country or region plans to outlay to achieve approved targets
            and other COP activities during the 12-month fiscal year. Applied
            Pipeline funds are funds that were previously programmed and are
            being carried forward into a new planning year. All outlays
            anticipated for all mechanisms (new, continuing, and closing)
            occurring during the implementation period are included within the
            planning level. The COP/ROP planning levels can be updated
            throughout the year with an Operational Plan Update (OPU). This
            dataset contains OPU data approved one week prior to the date of the
            data set release. OPUs approved after this time will not be
            included. Additionally, for mechanisms with partners that are to be
            determined (TBD) because they have not yet been awarded, budgets are
            aggregated to the OU by agency level such that the amount of each
            TBD mechanism is not known, and partner names will appear as TBD.
          </Typography>

          <Typography>
            In this dataset, new funding amounts for a mechanism are displayed
            in the four new funding accounts (ESF, GAP, GHP-State and
            GHP-USAID), which total to &quot;total new funding sources.&quot;
            ESF is a new funding account that was introduced in COP21 to account
            for funding received through the American Rescue Plan Act of 2021. A
            mechanism’s applied pipeline amount is listed next to the new
            funding accounts. The total new funding sources and applied pipeline
            for a mechanism are then totaled in &quot;total planned
            funding.&quot; This total planned funding is then classified using
            the financial classifications to describe that mechanism&apos;s
            purpose by intervention (program area, service delivery vs.
            non-service delivery, and beneficiary). Finally, the dataset
            contains cross-cutting attribute data that additionally categorize
            the planned funding.
          </Typography>

          <Typography>
            It is crucial to note that in this dataset, the Program Management
            budget for each mechanism has been &quot;masked&quot; to protect the
            sensitivity of this information. Program Management amounts are
            distributed proportionally to the other interventions at the
            mechanism, such that no mechanism will show any Program Management
            amount, but the Program Management amount will be contained in the
            amounts shown for all other entries for that mechanism. The actual
            Program Management amount for each OU can be found in the OU Budget
            by Financial Classifications dataset, but this data will not be
            available at the mechanism level.
          </Typography>

          <Typography role="note" className="rules">
            Rules to produce this dataset:
          </Typography>
          <div role="navigation">
            <Typography>
              <ul>
                <li>
                  <Typography variant="body2">
                    Contains budget data from COP20 to COP23.
                  </Typography>
                </li>
                <li>
                  <Typography variant="body2">
                    Budget amounts are aggregated to the mechanism ID and
                    planning year level and disaggregated to the financial
                    classifications level.
                  </Typography>
                </li>
                <li>
                  <Typography variant="body2">
                    For any TBD partners, some mechanism details are redacted
                    and budgets are aggregated to the OU by agency level such
                    that the amount of each TBD mechanism is not known.
                  </Typography>
                </li>
                <li>
                  <Typography variant="body2">
                    To maintain confidentiality, budget amounts tied to the
                    financial classification program area of “Program
                    Management” in each mechanism are distributed to other
                    non-Program Management interventions at that mechanism using
                    a proportional distribution. Due to these calculations,
                    rounding errors of $1 or $2 may occur such that the total
                    amounts of each mechanism in each funding account may be
                    nominally different from their true value.
                  </Typography>
                </li>
                <li>
                  <Typography variant="body2">
                    Similar to the above bullet, rounding errors driven by the
                    distribution of Program Management to non-Program Management
                    interventions may result in the sum of the new funding
                    accounts not exactly equaling the new funding total. These
                    discrepancies will be nominal ($1-$2 per mechanism
                    typically).
                  </Typography>
                </li>
                <li>
                  <Typography variant="body2">
                    For mechanisms that contain solely Program Management
                    interventions, such as those with only the Sub-program of
                    “Program Management: IM Closeout Costs”, the mechanism’s
                    identifying information and intervention details have been
                    redacted and the budget amount have been aggregated to the
                    total OU level.
                  </Typography>
                </li>
                <li>
                  <Typography variant="body2">
                    Cross-cutting attribute data for any given mechanism’s
                    Program Management intervention are distributed to the other
                    interventions in that mechanism or aggregated to a single
                    row, similar to the previous bullet.
                  </Typography>
                </li>
                <li>
                  <Typography variant="body2">
                    Information on how the ‘Allocated Beneficiary’ column is
                    calculated can be found in the{" "}
                    <a
                      href="https://help.datim.org/hc/en-us/articles/22561900657172-Allocated-Beneficiary-Guide"
                      target="_blank"
                      rel="noopener noreferrer"
                      style={{ color: theme.linkColor, fontWeight: 800 }}
                    >
                      Allocated Beneficiary Guide
                    </a>
                    .
                  </Typography>
                </li>
              </ul>
            </Typography>
          </div>
        </section>
        <div role="navigation">
          <Typography>
            <a
              style={{ color: theme.linkColor, fontWeight: 800 }}
              href={`${S3Path}additionalData/partner-budget-data/Public_Partner_Budget_by_Financial_Classifications.zip`}
              target="_blank"
              rel="noopener noreferrer"
            >
              Public_Partner_Budget_by_Financial_Classifications.zip
            </a>
          </Typography>
          <Typography>
            <a
              style={{ color: theme.linkColor, fontWeight: 800 }}
              href={`${S3Path}additionalData/partner-budget-data/Public_Partner_Budget_by_Financial_Classifications_with_Allocated_Beneficiary.zip`}
              target="_blank"
              rel="noopener noreferrer"
            >
              Public_Partner_Budget_by_Financial_Classifications_with_Allocated_Beneficiary.zip
            </a>
          </Typography>
        </div>

        <section className="section" tabIndex={0} id="partner-expenditure-data">
          <Typography variant="h2" role="header">
            Partner Expenditure Dataset
          </Typography>

          <Typography>
            This dataset contains mechanism-level expenditure data by partner,
            disaggregated to the financial classifications level.
          </Typography>

          <Typography role="note" className="rules">
            Rules to produce this dataset:
          </Typography>
          <div role="navigation">
            <Typography>
              <ul>
                <li>
                  <Typography variant="body2">
                    Contains data from FY18-FY23.
                  </Typography>{" "}
                </li>
                <li>
                  <Typography variant="body2">
                    Expenditures are aggregated to the mechanism ID/fiscal year
                    level and disaggregated to the financial classification
                    level.
                  </Typography>
                </li>
                <li>
                  <Typography variant="body2">
                    To maintain confidentiality, expenditure amounts tied to the
                    financial classification program area of Program Management
                    in each mechanism are distributed to other non-Program
                    Management interventions at that mechanism using a
                    proportional distribution. Due to these calculations,
                    rounding errors of $1 or $2 may occur such that the total
                    amounts of each mechanism in each funding account may be
                    nominally different from their true value.
                  </Typography>
                </li>
                <li>
                  <Typography variant="body2">
                    For mechanisms that contain solely Program Management
                    interventions, such as those with only the Sub-program of
                    “Program Management: IM Closeout Costs”, the mechanism’s
                    identifying information and intervention details have been
                    redacted and the budget amount have been aggregated to the
                    total OU level.
                  </Typography>
                </li>
              </ul>
            </Typography>
          </div>
        </section>
        <div role="navigation">
          <Typography>
            <a
              style={{ color: theme.linkColor, fontWeight: 800 }}
              href={`${S3Path}additionalData/partner-expenditure-data/Public_Partner_Expenditure.zip`}
            >
              Public_Partner_Expenditure.zip
            </a>
          </Typography>
        </div>

        <section className="section" tabIndex={0} id="PMD">
          <Typography variant="h2" role="header">
            Partner MER Dataset
          </Typography>

          <Typography variant="body1">
            Partner data for MER Targets and MER Results for Fiscal Years (FY)
            2018 - 2024 (starting October 2017 through the latest quarter’s
            data), presented in two data sets: One data set presenting only the
            total numerator and total denominators at the partner level for each
            indicator, and one data set presenting partner data disaggregated by
            Age and Sex.
          </Typography>
          <Typography>
            The Age/Sex dataset includes all data reported to PEPFAR with Age
            and Sex information. Though{" "}
            <a
              href="https://datim.zendesk.com/hc/en-us/articles/360000084446-MER-Indicator-Reference-Guides"
              target="_blank"
              rel="noopener noreferrer"
              style={{ color: theme.linkColor, fontWeight: 800 }}
            >
              MER Guidance
            </a>{" "}
            includes smaller age bands (&lt;1, 1-5, etc), this file presents
            this information using aggregate age bands of &lt;15, 15+ and
            Unknown Age. (Please note: Some indicators are not collected with
            Age/Sex disaggregates and so are not included in this dataset.)
          </Typography>
          <Typography>
            The Top line dataset only includes the Total Numerator and Total
            Denominator information. (Please note: Please note that FY2018 data
            (and prior years in other datasets) included Total data reported to
            PEPFAR separately from the Age/Sex information. This implies that it
            is possible to find a summation of the Age/Sex data which is
            different than the Total Numerator/Denominator information within
            FY2018.)
          </Typography>

          <Typography role="note" className="rules">
            Rules to produce this dataset:
          </Typography>
          <div role="navigation">
            <Typography>
              <ul>
                <li>
                  <Typography variant="body2">
                    For any partners which are not active or TBD, the MER data
                    is not included.
                  </Typography>
                </li>
                <li>
                  <Typography variant="body2">
                    TX_RTT inclusion criteria was clarified in MER 2.5 Guidance
                    (FY2021 implementation); MER Guidance should be referenced
                    when reviewing TX_RTT annual trends.
                  </Typography>
                </li>
                <li>
                  <Typography variant="body2">
                    HTS_INDEX and HTS_RECENT data is masked in the Target and
                    Qtr1-4 columns if the data reported to PEPFAR is less than
                    50. The true amount is replaced with &quot;value &lt;
                    50&quot;
                  </Typography>
                </li>
                <li>
                  <Typography variant="body2">
                    When two Implementing Partners operate at the same facility,
                    any duplicate results reported at that facility are manually
                    &apos;deduplicated&apos; by PEPFAR staff to generate an
                    aggregate total of individuals reached at the facility. This
                    aggregate total is used to produce country-level results.
                    Due to the nature of this data set at the partner level,
                    this manual deduplication adjustment has been removed from
                    this dataset so partners’ data can be reviewed in full.
                    Please note, this data set is intended to be used to
                    view/access individual partner-level performance, partner
                    data should not be aggregated to generate country or SNU
                    level data, because doing this without the deduplication
                    adjustment would inflate the aggregate total. Other data
                    sets available on Panorama Spotlight includes this aggregate
                    data with deduplication adjustment and so can be used to
                    produce aggregate totals between partners.
                  </Typography>
                </li>
                <li>
                  <Typography variant="body2">
                    Narrative, National/Subnational and IMPATT data is not
                    included in this dataset.
                  </Typography>
                </li>
              </ul>
            </Typography>
          </div>
        </section>
        <div role="heading">
          <Typography>
            <a
              style={{ color: theme.linkColor, fontWeight: 800 }}
              href={`${S3Path}additionalData/partner-mer-data/Public_Partner_MER_AgeSex.zip`}
            >
              Public_Partner_MER_AgeSex.zip
            </a>
          </Typography>
          <Typography>
            <a
              style={{ color: theme.linkColor, fontWeight: 800 }}
              href={`${S3Path}additionalData/partner-mer-data/Public_Partner_MER_TopLine.zip`}
              onBlur={() => leavePage()}
            >
              Public_Partner_MER_TopLine.zip
            </a>
          </Typography>
        </div>
      </div>
    </div>
  );

  useEffect(() => {
    renderTOC();
  }, []);

  function renderTOC() {
    let sectionElement, headerElement, title, link;
    const sectionHeaders = document.getElementsByClassName("section");
    const links = [];
    for (let i = 0; i < sectionHeaders.length; i++) {
      logger.debug(`create link ${i}`);
      sectionElement = sectionHeaders[i];
      headerElement = sectionElement.getElementsByTagName("h2")[0];
      if (headerElement) {
        title = headerElement.innerText;
        link = "#" + sectionElement.id;
        links.push(createThemeLink(link, title));
      }
    }
    setTocData(links);
  }

  return (
    <SecondaryPageContainer
      contents={pageContents}
      showToc={true}
      pageHeaderId="page-heading"
      isSidebarCollapsed={props.isSidebarCollapsed}
      data={tocData}
    />
  );
};

AdditionalDataPage.propTypes = {
  isSidebarCollapsed: PropTypes.bool.isRequired,
};

export default AdditionalDataPage;
