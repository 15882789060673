import React, { useEffect, useState } from "react";
import SecondaryPageContainer from "components/SecondaryPageContainer/NewSecondaryPageContainer";
import Typography from "@mui/material/Typography";
import List from "@mui/material/List";
import ListItemText from "@mui/material/ListItemText";
import * as common from "common";
import ReactGA from "react-ga4";
import PropTypes from "prop-types";
import { createThemeLink } from "services/FontTheme";
import { useDispatch, useSelector } from "react-redux";
import { updateNavLink } from "stateMgmt/daaSlice";
import { selectTheme } from "stateMgmt/complianceSlice";
import "./datasets.scss";
import logger from "services/LoggingService";

const s3path = common.getS3Url();

const DatasetsPage = (props) => {
  const dispatch = useDispatch();
  const [tocData, setTocData] = useState([]);
  const theme = useSelector(selectTheme);

  ReactGA.send({
    hitType: "pageview",
    page: "/components/MainContentArea/DatasetsPage/DatasetsPage.js",
    title: "Datasets",
  });

  // const listStyle = {
  //   color: "#596067",
  //   listStyleType: "none",
  // };

  const leavePage = () => {
    dispatch(updateNavLink({ calllingLink: "DAT" }));
  };

  const pageContents = (
    <div
      className="row DATASETS"
      id="Datasets_page-top"
      data-testid="Datasets_Page"
    >
      <div className="text-left">
        <Typography variant="h1" id="page-heading" role="heading" tabIndex={0}>
          Datasets
        </Typography>

        <Typography
          style={{ color: theme.warningColor }}
          tabIndex={0}
          role="note"
        >
          Please note for MER Datasets: Due to data quality improvement efforts,
          Nigeria FY23 Q4 MER data are excluded from all datasets. The inclusion
          of the new geographic regionalization structure has resulted in
          underreporting of historic FY15-20 data for Regions and regional
          countries, this will be fixed during the Q4 release in December.
        </Typography>

        <section className="section" id="PDD" role="heading" tabIndex={0}>
          <Typography variant="h2">PEPFAR Downloadable Datasets</Typography>

          <Typography>
            The data used to populate visuals in the Dashboard section of
            Spotlight can be found here in the form of downloadable datasets.
            These dataset are intended to be downloaded as compressed (zip)
            files, which contain text files, csv (comma separated values) files
            and Microsoft Excel spreadsheet (.xlsx) format and imported into a
            data analyst tool for analysis and review. Codebooks and guidance
            documents can be found within each programmatic section. For more
            information on how to work with the datasets found on Spotlight,
            please reference this{" "}
            <a
              style={{ color: theme.linkColor, fontWeight: 800 }}
              href={`${s3path}pepfar_downloadables/Spotlight_Dataset_FAQs.docx`}
              title="Link TO FAQ document"
              aria-label="Link TO FAQ document"
            >
              FAQ document
            </a>
          </Typography>
        </section>

        <section className="section" id="FMD">
          <Typography variant="h2" role="heading" tabIndex={0}>
            Financial Management Datasets
          </Typography>

          <div role="note" tabIndex={0}>
            <Typography variant="h3" role="heading" className="page-subheader">
              PEPFAR OU Budget to Implementing Partners
            </Typography>
            <Typography>
              The PEPFAR OU Budgets by Financial Classifications dataset
              provides, at an OU level, what was budgeted for implementing
              partners for Care & treatment, HIV testing services, Prevention,
              Socio-economic, and Above site programs for FY2004-FY2024. Budgets
              for the period were allocated across PEPFAR budget codes and then
              mapped to the financial classifications. Information on how the
              &quot;Allocated Beneficiary&quot; column is calculated can be
              found in the{" "}
              <a
                style={{ color: theme.linkColor, fontWeight: 800 }}
                href={`${s3path}additionalData/partner-budget-data/Allocated_Beneficiary_Guide.pdf`}
                download
                target="_blank"
                rel="noopener noreferrer"
              >
                Allocated Beneficiary Guide.
              </a>
            </Typography>
          </div>

          <div role="navigation">
            <Typography variant="h3">
              <List dense sx={{ pl: 10, color: theme.linkColor }}>
                <ListItemText>
                  <a
                    style={{ color: theme.linkColor, fontWeight: 800 }}
                    href={`${s3path}fin_mngt/budget_by_program/PEPFAR_OU_Budgets_by_Financial_Classifications.txt`}
                    download
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    PEPFAR_OU_Budgets_by_Financial_Classifications.txt
                  </a>
                </ListItemText>
                <ListItemText>
                  <a
                    style={{ color: theme.linkColor, fontWeight: 800 }}
                    href={`${s3path}fin_mngt/budget_by_program/BudgetbyProgramCodebook.csv`}
                    target="_blank"
                    rel="noreferrer"
                  >
                    Budget Financial Classifications Codebook.csv
                  </a>
                </ListItemText>
                <ListItemText>
                  <a
                    style={{ color: theme.linkColor, fontWeight: 800 }}
                    href={`${s3path}fin_mngt/budget_by_program/BudgetCode-FinancialClassificationsCrosswalk.csv`}
                    target="_blank"
                    rel="noreferrer"
                  >
                    Budget Code Crosswalk to Financial Classifications.csv
                  </a>
                </ListItemText>
                <ListItemText>
                  <span style={{ color: theme.color }}>All Files: </span>
                  <a
                    style={{ color: theme.linkColor, fontWeight: 800 }}
                    href={`${s3path}fin_mngt/budget_by_program/PEPFAROUBudgetsbyFinancialClassifications.zip`}
                    target="_blank"
                    rel="noreferrer"
                  >
                    PEPFAR OU Budgets by Financial Classifications.zip
                  </a>
                </ListItemText>
              </List>
            </Typography>
          </div>

          <div role="note" tabIndex={0}>
            <Typography variant="h3" role="heading">
              Planned Funding by Budget Code
            </Typography>
            <Typography>
              <p>
                The PEPFAR OU Budgets by Budget Code dataset provides, at an OU
                level, what was budgeted for implementing partners during the
                COP/ROP processes for FY2004-FY2022. As of the conclusion of the
                COP21 planned funding cycle in FY21 Q3, budget code data was no
                longer collected. Individual budget code entries will indicate
                &#34;NOT SPECIFIED&#34; from FY21 Q3 onwards. This dataset has
                been capped at COP21 and will no longer be updated.
              </p>
              <p>
                Technical guidance as to what can be included under each budget
                code can be updated annually; budget code guidance is included
                in the applicable COP/ROP guidance for that year (current and
                previous year COP/ROP guidance documents are posted on{" "}
                <a
                  style={{ color: theme.linkColor, fontWeight: 800 }}
                  href={
                    "https://www.state.gov/wp-content/uploads/2022/01/COP22-Guidance-Final_508-Compliant.pdf"
                  }
                  target="_blank"
                  rel="noreferrer"
                >
                  https://www.state.gov/wp-content/uploads/2022/01/COP22-Guidance-Final_508-Compliant.pdf
                </a>
              </p>
            </Typography>
          </div>

          <div role="navigation">
            <Typography variant="h3">
              <List dense sx={{ pl: 10, color: theme.linkColor }}>
                <ListItemText>
                  <a
                    style={{ color: theme.linkColor, fontWeight: 800 }}
                    href={`${s3path}fin_mngt/budget_by_budget_code/PEPFAR_OU_Budgets_by_Budget_Code.txt`}
                    download
                    target="_blank"
                    rel="noreferrer noopener"
                  >
                    PEPFAR_OU_Budgets_by_Budget_Code.txt
                  </a>
                </ListItemText>
                <ListItemText>
                  <a
                    style={{ color: theme.linkColor, fontWeight: 800 }}
                    href={`${s3path}fin_mngt/budget_by_budget_code/BudgetCodeCodebook.csv`}
                    target="_blank"
                    rel="noreferrer"
                  >
                    Budget Code Codebook.csv
                  </a>
                </ListItemText>
                <ListItemText>
                  <span style={{ color: theme.color }}>All Files: </span>
                  <a
                    style={{ color: theme.linkColor, fontWeight: 800 }}
                    href={`${s3path}fin_mngt/budget_by_budget_code/PEPFAROUBudgetsbyBudgetCode.zip`}
                    target="_blank"
                    rel="noreferrer"
                  >
                    PEPFAR OU Budgets by Budget Code.zip
                  </a>
                </ListItemText>
              </List>
            </Typography>

            <br />
            <Typography variant="h3" role="heading">
              PEPFAR OU Commodities Procurement
            </Typography>
            <Typography>
              <p>
                The Spotlight public commodities dataset provides, at an OU
                level, what was budgeted for commodities procurement from COP18
                to the current year. Procurements are classified using the
                PEPFAR Major and Minor Category classification and a
                standardized item name. In addition to the planned funding or
                budget, the dataset includes the quantity planned for
                procurement.
              </p>
              <List dense sx={{ pl: 10, color: theme.linkColor }}>
                <ListItemText>
                  <a
                    style={{ color: theme.linkColor, fontWeight: 800 }}
                    href={`${s3path}fin_mngt/commodities/PEPFAR_OU_Commodites_Procurements.zip`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    PEPFAR_OU_Commodites_Procurements.zip
                  </a>
                </ListItemText>
              </List>
            </Typography>
          </div>

          <div role="navigation" tabIndex={0}>
            <Typography variant="h3" role="heading">
              PEPFAR OU Expenditure by Implementing Partners
            </Typography>
            <Typography>
              The PEPFAR Program Expenditures datasets provide, at an OU level,
              what was expended in FY2015-FY2023 by implementing partners for
              Care & treatment, HIV testing services, Prevention,
              Socio-economic, Above site programs, and Program management in
              US$. FY2018-FY2023 expenditures were reported against the
              financial classifications for program, beneficiary, and object
              classes. FY2015-FY2017 expenditures were reported through
              expenditure analysis (EA) classifications and mapped to the new
              financial classifications.
            </Typography>
          </div>

          <div role="navigation">
            <Typography>
              <List dense sx={{ pl: 10, color: theme.linkColor }}>
                <ListItemText>
                  <a
                    style={{ color: theme.linkColor, fontWeight: 800 }}
                    href={`${s3path}fin_mngt/pepfar_expenditures_by_program/PEPFAR_Program_Expenditures.txt`}
                    download
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    PEPFAR_Program_Expenditures.txt
                  </a>
                </ListItemText>
                <ListItemText>
                  <a
                    style={{ color: theme.linkColor, fontWeight: 800 }}
                    href={`${s3path}fin_mngt/pepfar_expenditures_by_program/PEPFARExpenditureAnalysis.csv`}
                    target="_blank"
                    rel="noreferrer"
                  >
                    PEPFAR Expenditure Analysis.csv
                  </a>
                </ListItemText>
                <ListItemText>
                  <a
                    style={{ color: theme.linkColor, fontWeight: 800 }}
                    href={`${s3path}fin_mngt/pepfar_expenditures_by_program/ExpendituresCodebook.csv`}
                    target="_blank"
                    rel="noreferrer"
                  >
                    Expenditures (Financial Classifications) Codebook.csv
                  </a>
                </ListItemText>
                <ListItemText>
                  <a
                    style={{ color: theme.linkColor, fontWeight: 800 }}
                    href={`${s3path}fin_mngt/pepfar_expenditures_by_program/Expenditures_EAClassifications_Codebook.csv`}
                    target="_blank"
                    rel="noreferrer"
                  >
                    Expenditures (EA Classifications) Codebook.csv
                  </a>
                </ListItemText>
                <ListItemText>
                  <a
                    style={{ color: theme.linkColor, fontWeight: 800 }}
                    href={`${s3path}fin_mngt/pepfar_expenditures_by_program/EAtoFinancialClassificationsCrosswalk.xlsx`}
                    target="_blank"
                    rel="noreferrer"
                  >
                    EA to Financial Classifications Crosswalk.xlsx
                  </a>
                </ListItemText>
                <ListItemText>
                  <span style={{ color: theme.color }}>All Files: </span>
                  <a
                    style={{ color: theme.linkColor, fontWeight: 800 }}
                    href={`${s3path}fin_mngt/pepfar_expenditures_by_program/PEPFARProgramExpenditures.zip`}
                    target="_blank"
                    rel="noreferrer"
                  >
                    PEPFAR Program Expenditures.zip
                  </a>
                </ListItemText>
              </List>
            </Typography>
          </div>
        </section>

        <section className="section" id="MDD">
          <div role="heading" tabIndex={0}>
            <Typography variant="h2">
              Monitoring, Evaluation, and Reporting (MER) Datasets
            </Typography>

            <Typography>
              The data found on the PEPFAR Dashboards and in the datasets of
              this section, provide a view of a country’s or region’s targets
              and results against a selection of indicators that demonstrate
              PEPFAR’s progress and contributions to combat global HIV/AIDS. The
              Targets and Results presented in these datasets represent the
              collective targets and achievements for all U.S. Government
              agencies that implement PEPFAR. For more information on the
              indicators by which targets and results are reported, please
              consult the latest MER Indicator Reference Guide found in the
              Guidance section of{" "}
              <a
                style={{ color: theme.linkColor, fontWeight: 800 }}
                href=" https://www.state.gov/reports-pepfar/"
                target="blank"
              >
                https://www.state.gov/reports-pepfar/
              </a>
            </Typography>
          </div>

          <div role="heading" tabIndex={0}>
            <Typography variant="h3">
              Geographical Analysis – Operating Unit
            </Typography>
            <Typography>
              Quarterly Results and yearly Targets by Operating Unit for Fiscal
              Years 2016 – 2024 and commonly used indicators across PEPFAR’s
              Program Areas.
            </Typography>
          </div>

          <div role="navigation">
            <Typography>
              <List dense sx={{ pl: 10, color: theme.linkColor }}>
                <ListItemText>
                  <a
                    style={{ color: theme.linkColor, fontWeight: 800 }}
                    href={`${s3path}mer_downloadables/Geographical_Analysis_Operating_Unit.zip`}
                    target="_blank"
                    rel="noreferrer"
                  >
                    Geographical_Analysis_Operating_Unit.zip
                  </a>
                </ListItemText>
              </List>
            </Typography>
          </div>

          <div role="heading" tabIndex={0}>
            <Typography variant="h3" role="heading">
              Geographical Analysis – Sub-National Unit
            </Typography>
            <Typography>
              Quarterly Results and yearly Targets by Operating Unit and
              Sub-National Units 1-3 for Fiscal Years 2016 – 2024 and commonly
              used indicators across PEPFAR&#39;s Program Areas.
            </Typography>
          </div>

          <div role="navigation">
            <Typography>
              <List dense sx={{ pl: 10, color: theme.linkColor }}>
                <ListItemText>
                  <a
                    style={{ color: theme.linkColor, fontWeight: 800 }}
                    href={`${s3path}mer_downloadables/Geographical_Analysis_Sub_National_Unit.zip`}
                    target="_blank"
                    rel="noreferrer"
                  >
                    Geographical_Analysis_Sub_National_Unit.zip
                  </a>
                </ListItemText>
              </List>
            </Typography>
          </div>

          <div role="heading" tabIndex={0}>
            <Typography variant="h3" role="heading">
              Enhanced Geographical Analysis - Sub-National Unit
            </Typography>
            <Typography>
              Quarterly Results and yearly Targets by Operating Unit and
              Sub-National Units 1-3 for Fiscal Years 2016-2024 for a more
              comprehensive array of indicators across PEPFAR&#39;s Program
              Areas.
            </Typography>
          </div>

          <div role="navigation">
            <Typography>
              <List dense sx={{ pl: 10, color: theme.linkColor }}>
                <ListItemText>
                  <a
                    style={{ color: theme.linkColor, fontWeight: 800 }}
                    href={`${s3path}mer_downloadables/Enhanced_Geographical_Analysis.zip`}
                    target="_blank"
                    rel="noreferrer"
                  >
                    Enhanced_Geographical_Analysis.zip
                  </a>
                </ListItemText>
              </List>
            </Typography>
          </div>

          <div role="heading" tabIndex={0}>
            <Typography variant="h3" role="heading">
              Operating Unit by Fine Age & Sex
            </Typography>
            <Typography>
              Quarterly Results and yearly targets by Operating Unit, Fine Age
              and Sex for Fiscal Years 2019 – 2024. Note: Reporting for age
              bands above 50 is optional and not available for all OUs; however,
              the aggregate 50+ age band is available for all OUs.
            </Typography>
          </div>

          <div role="navigation">
            <Typography>
              <List dense sx={{ pl: 10, color: theme.linkColor }}>
                <ListItemText>
                  <a
                    style={{ color: theme.linkColor, fontWeight: 800 }}
                    href={`${s3path}mer_downloadables/Operating_Unit_by_Fine_Age_and_Sex.zip`}
                    target="_blank"
                    rel="noreferrer"
                  >
                    Operating Unit by Fine Age & Sex
                  </a>
                </ListItemText>
              </List>
            </Typography>
          </div>

          <div role="heading" tabIndex={0}>
            <Typography variant="h3">
              Country level Results by Modality, Coarse Age and Sex
            </Typography>
            <Typography>
              Quarterly Results and yearly Targets by Operating Unit, Country,
              Modality, Coarse Age and Sex for Fiscal Years 2018 – 2024.
            </Typography>
          </div>

          <div role="navigation">
            <Typography>
              <List dense sx={{ pl: 10, color: theme.linkColor }}>
                <ListItemText>
                  <a
                    style={{ color: theme.linkColor, fontWeight: 800 }}
                    href={`${s3path}mer_downloadables/Country_Level_Results_by_Modality_Coarse_Age_and_Sex.zip`}
                    target="_blank"
                    rel="noreferrer"
                  >
                    Country Level Results by Modality Coarse Age and Sex.zip
                  </a>
                </ListItemText>
              </List>
            </Typography>
          </div>

          <div role="heading" tabIndex={0}>
            <Typography variant="h3" role="heading">
              Clinical Cascade – Quarterly Results
            </Typography>
            <Typography>
              Quarterly Results and yearly Targets by Operating Unit and
              Sub-National Units 1–3 for Fiscal Years 2016 – 2024 and the
              following subset of Testing and Treatment indicators: HTS_TST
              (People receiving testing and counseling services), HTS_SELF (HIV
              Self Test Kits Distributed), HTS_TST_POS (People newly testing
              positive for HIV), TX_CURR (People currently receiving ART),
              TX_NEW (People newly enrolled in ART), TX_PVLS (Number of ART
              Patients with a suppressed VL results (&lt;1,000 copies/mL) within
              the Past 12 months) and TX_NET_NEW (Net Increase or Decrease in
              ART Patients).
            </Typography>
          </div>

          <div role="navigation">
            <Typography>
              <List dense sx={{ pl: 10, color: theme.linkColor }}>
                <ListItemText>
                  <a
                    style={{ color: theme.linkColor, fontWeight: 800 }}
                    href={`${s3path}mer_downloadables/Clinical_Cascade_Quarterly_Results.zip`}
                    target="_blank"
                    rel="noreferrer"
                  >
                    Clinical_Cascade_Quarterly_Results.zip
                  </a>
                </ListItemText>
              </List>
            </Typography>
          </div>

          <div role="heading" tabIndex={0}>
            <Typography variant="h3" role="heading">
              Clinical Cascade – Results by Coarse Age and Sex
            </Typography>
            <Typography>
              Quarterly Results and yearly Targets by Operating Unit,
              Sub-National Units 1–3, Coarse Age and Sex for Fiscal Years 2016 –
              2024 and the following subset of Testing and Treatment indicators:
              HTS_TST (People receiving testing and counseling services),
              HTS_SELF (HIV Self Test Kits Distributed), HTS_TST_POS (People
              newly testing positive for HIV), TX_CURR (People currently
              receiving ART), TX_NEW (People newly enrolled in ART), TX_PVLS
              (Number of ART Patients with a suppressed VL results (&lt;1,000
              copies/mL) within the Past 12 months), and TX_NET_NEW (Net
              Increase or Decrease in ART Patients).
            </Typography>
          </div>

          <div role="navigation">
            <Typography>
              <List dense sx={{ pl: 10, color: theme.linkColor }}>
                <ListItemText>
                  <a
                    style={{ color: theme.linkColor, fontWeight: 800 }}
                    href={`${s3path}mer_downloadables/Clinical_Cascade_Results_by_Coarse_Age_and_Sex.zip`}
                    target="_blank"
                    rel="noreferrer"
                  >
                    Clinical_Cascade_Results_by_Coarse_Age_and_Sex.zip
                  </a>
                </ListItemText>
              </List>
            </Typography>
          </div>

          <div role="heading" tabIndex={0}>
            <Typography variant="h3" role="heading">
              Clinical Cascade –Results by Fine Age and Sex
            </Typography>
            <Typography>
              Quarterly Results and yearly Targets by Operating Unit,
              Sub-National Unit 1, Fine Age, Coarse Age and Sex for Fiscal Years
              2019 - 2024 and the following subset of Testing and Treatment
              indicators: HTS_TST (People receiving testing and counseling
              services), HTS_TST_POS (People newly testing positive for HIV),
              TX_CURR (People currently receiving ART), TX_NEW (People newly
              enrolled in ART), TX_PVLS (12 Months Viral Load &lt; 1000), and
              TX_NET_NEW (Net Increase or Decrease in ART Patients). Note:
              Reporting for age bands above 50 is optional and not available for
              all OUs; however, the aggregate 50+ age band is available for all
              OUs. Note: Reporting for age bands above 50 is optional and not
              available for all OUs; however, the aggregate 50+ age band is
              available for all OUs.
            </Typography>
          </div>

          <div role="navigation">
            <Typography>
              <List dense sx={{ pl: 10, color: theme.linkColor }}>
                <ListItemText>
                  <a
                    style={{ color: theme.linkColor, fontWeight: 800 }}
                    href={`${s3path}mer_downloadables/Clinical_Cascade_Results_by_Fine_Age_and_Sex.zip`}
                    target="_blank"
                    rel="noreferrer"
                  >
                    Clinical_Cascade_Results_by_Fine_Age_and_Sex.zip
                  </a>
                </ListItemText>
              </List>
            </Typography>
          </div>

          <div role="heading" tabIndex={0}>
            <Typography variant="h3">
              Voluntary Male Medical Circumcisions
            </Typography>
            <Typography>
              Yearly Results by Operating Unit for Fiscal Years 2007 – 2023.
            </Typography>
          </div>

          <div role="navigation">
            <Typography>
              <List dense sx={{ pl: 10, color: theme.linkColor }}>
                <ListItemText>
                  <a
                    style={{ color: theme.linkColor, fontWeight: 800 }}
                    href={`${s3path}mer_downloadables/Voluntary_Male_Medical_Circumcisions_Results_by_Operating_Unit.zip`}
                    target="_blank"
                    rel="noreferrer"
                  >
                    Voluntary_Male_Medical_Circumcisions_Results_by_Operating_Unit.zip
                  </a>
                </ListItemText>
              </List>
            </Typography>
          </div>

          <div role="heading" tabIndex={0}>
            <Typography variant="h3" role="heading">
              Partner Performance
            </Typography>
            <Typography>
              Quarterly Results and yearly Targets by Operating Unit, PSNU,
              Implementing Partner (masked) and Funding Agency for Fiscal Years
              2017 – 2024 and commonly used indicators across PEPFAR’s Program
              Areas.
            </Typography>
          </div>

          <div role="navigation">
            <Typography>
              <List dense sx={{ pl: 10, color: theme.linkColor }}>
                <ListItemText>
                  <a
                    style={{ color: theme.linkColor, fontWeight: 800 }}
                    href={`${s3path}mer_downloadables/Partner_Performance.zip`}
                    target="_blank"
                    rel="noreferrer"
                  >
                    Partner_Performance.zip
                  </a>
                </ListItemText>
              </List>
            </Typography>
          </div>

          <div role="heading" tabIndex={0}>
            <Typography variant="h3" role="heading">
              Site Performance
            </Typography>
            <Typography>
              Quarterly Results and yearly Targets by Operating Unit, PSNU and
              Facility/Site (masked) for Fiscal Years 2015 – 2024 and commonly
              used indicators across PEPFAR&#39;s Program Areas.
            </Typography>
          </div>

          <div role="navigation">
            <Typography>
              <List dense sx={{ pl: 10, color: theme.linkColor }}>
                <ListItemText>
                  <a
                    style={{ color: theme.linkColor, fontWeight: 800 }}
                    href={`${s3path}mer_downloadables/Site_Performance_2021_plus.zip`}
                    target="_blank"
                    rel="noreferrer"
                  >
                    Site_Performance_2021_plus.zip
                  </a>
                </ListItemText>

                <ListItemText>
                  <a
                    style={{ color: theme.linkColor, fontWeight: 800 }}
                    href={`${s3path}mer_downloadables/Site_Performance_2016-2020.zip`}
                    target="_blank"
                    rel="noreferrer"
                  >
                    Site Performance 2016-2020
                  </a>
                </ListItemText>
              </List>
            </Typography>
          </div>

          <div role="heading" tabIndex={0}>
            <Typography variant="h3" role="heading">
              Cervical Cancer Analysis
            </Typography>
            <Typography>
              Bi-annual Results by Operating Unit and Fine Age for Fiscal Years
              2018-2024 and the following Cervical Cancer indicators: CXCA_SCRN
              (Number of HIV-positive women on ART screened for cervical
              cancer), CXCA_SCRN_POS (Number of HIV-positive women on ART who
              screened positive for cervical cancer), and CXCA_TX (Number of
              cervical cancer screen-positive women who are HIV-positive and on
              ART and received precancerous cervical cancer treatment).
            </Typography>
          </div>

          <div role="navigation">
            <Typography>
              <List dense sx={{ pl: 10, color: theme.linkColor }}>
                <ListItemText>
                  <a
                    style={{ color: theme.linkColor, fontWeight: 800 }}
                    href={`${s3path}mer_downloadables/Cervical_Cancer_Analysis.zip`}
                    target="_blank"
                    rel="noreferrer"
                  >
                    Cervical_Cancer_Analysis.zip
                  </a>
                </ListItemText>
              </List>
            </Typography>
          </div>
        </section>

        <section className="section" id="SDD" role="heading">
          <div role="heading" tabIndex={0}>
            <Typography variant="h2">
              Site Improvement through Monitoring System (SIMS) Datasets
            </Typography>
            <Typography>
              Global SIMS data are provided for FY19 Q2 onwards (i.e. January
              2019 onwards). This aligns with the beginning of SIMS 4.0
              implementation. Data are provided down to the SNU1 level for each
              CEE in each OU. Please refer to the{" "}
              <a
                style={{ color: theme.linkColor, fontWeight: 800 }}
                href={`${s3path}sims_downloadables/SIMS.Data.Dictionary.to.accompany.downloadable.datasets.pdf`}
                target="_blank"
                rel="noreferrer"
              >
                Data Dictionary
              </a>{" "}
              and the materials referenced in the Site Improvement Through
              Monitoring System dashboard Cover Page for details on how SIMS
              implementation occurs and how SIMS data are structured.
            </Typography>
            <Typography>
              This is a zip file contains data from all OUs as a separate files:{" "}
              <a
                style={{ color: theme.linkColor, fontWeight: 800 }}
                href={`${s3path}sims_downloadables/SIMS_Structured_Datasets_OU_SNU1_SIMS4.zip`}
              >
                SIMS_Structured_Datasets_OU_SNU1_SIMS4.zip
              </a>
            </Typography>
            <Typography>
              A single excel file containing data from all OUs:{" "}
              <a
                style={{ color: theme.linkColor, fontWeight: 800 }}
                aria-label="Link opens SIMS_Structured_Datasets_SNU1_SIMS4.xlsx"
                href={`${s3path}sims_downloadables/SIMS_Structured_Datasets_SNU1_SIMS4.xlsx`}
              >
                SIMS_Structured_Datasets_SNU1_SIMS4.xlsx
              </a>
            </Typography>
          </div>
        </section>

        <section className="section" id="PAS" role="heading">
          <div role="heading" tabIndex={0}>
            <Typography variant="h2">PASIT Downloadable Datasets</Typography>

            <Typography>
              The Planning Activities for Systems Investment Tool (PASIT) is as
              a PEPFAR Country or Regional Operational Plan (COP or ROP)
              planning tool designed to help connect gaps in public health
              systems with above-site activities and outcomes that can
              contribute to ending HIV/AIDS as a public health threat by 2030.
            </Typography>
            <Typography>
              PASIT data includes: activity descriptions, types of health system
              investments, activity budgets, who is implementing those
              activities, expected outcomes of activities etc. PASIT applies to
              the COP and ROP 2023 planning cycles.
            </Typography>
            <Typography>
              Please refer to the User Guide in the zip file for details and
              definitions of each data field.
            </Typography>
            <Typography>
              <a
                style={{ color: theme.linkColor, fontWeight: 800 }}
                href={`${s3path}pasit/PEFPAR_PASIT_by_OU_and_partner.zip`}
                onBlur={() => leavePage()}
              >
                PASIT COP23 dataset & User Guide
              </a>
            </Typography>
          </div>
        </section>
      </div>
    </div>
  );

  useEffect(() => {
    renderTOC();
  }, []);

  function renderTOC() {
    let sectionElement, headerElement, title, link;
    const sectionHeaders = document.getElementsByClassName("section");
    const links = [];
    for (let i = 0; i < sectionHeaders.length; i++) {
      logger.debug(`create link ${i}`);
      sectionElement = sectionHeaders[i];
      headerElement = sectionElement.getElementsByTagName("h2")[0];
      if (headerElement) {
        title = headerElement.innerText;
        link = "#" + sectionElement.id;
        links.push(createThemeLink(link, title));
      }
    }
    setTocData(links);
  }

  return (
    <SecondaryPageContainer
      contents={pageContents}
      showToc={true}
      pageHeaderId="page-heading"
      isSidebarCollapsed={props.isSidebarCollapsed}
      data={tocData}
    />
  );
};

DatasetsPage.propTypes = {
  isSidebarCollapsed: PropTypes.bool.isRequired,
};

export default DatasetsPage;
