import React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import {
  StyledHeaderCell,
  StyledTableCell,
  StripedTableRow,
  StyledFirstRowCell,
} from "factories/StyledComponents";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import Paper from "@mui/material/Paper";
import { useSelector } from "react-redux";
import { selectCompliance } from "../stateMgmt/complianceSlice";
import PropTypes from "prop-types";
import { v4 as uuid } from "uuid";
import logger from "../services/LoggingService";

const SmxTable = (props) => {
  const theme = useSelector(selectCompliance).theme;

  const HeaderCell = StyledHeaderCell(
    props.headerAlign,
    theme.tableHeaderFontColor,
    theme.tableHeaderColor
  );

  const TableCell = StyledTableCell(props.rowAlign, theme.color);

  const FirstRowCell = StyledFirstRowCell(
    props.firstRowAlign,
    theme.color,
    theme.tableFirstRowColor
  );

  const TableRow = StripedTableRow(theme.tableEvenColor, theme.tableOddColor);

  const subHeader = () => {
    logger.debug(`Sub Header: ${props.useSubHeader}`, props.subHeaderData);
    return props.useSubHeader === true ? (
      <TableRow>
        {props.subHeaderData.map((row) => (
          <FirstRowCell key={uuid()}>{row}</FirstRowCell>
        ))}
      </TableRow>
    ) : null;
  };

  return (
    <Paper
      sx={{
        width: "100%",
        margin: "auto",
        overflow: "auto",
      }}
    >
      <TableContainer>
        <Table aria-label="table">
          <TableHead>
            <TableRow>
              {props.headerData.map((row) => (
                <HeaderCell key={uuid()}>{row}</HeaderCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {subHeader()}
            {props.rowData.map((row) => (
              <TableRow key={uuid()}>
                {row.map((col) => (
                  <TableCell key={uuid()}>{col}</TableCell>
                ))}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  );
};

// Specifies the default values for props:
SmxTable.defaultProps = {
  subHeaderData: [],
  headerAlign: "left",
  firstRowAlign: "left",
  rowAlign: "left",
};

SmxTable.propTypes = {
  useSubHeader: PropTypes.bool.isRequired,
  headerData: PropTypes.array.isRequired,
  subHeaderData: PropTypes.array,
  rowData: PropTypes.array.isRequired,
  headerAlign: PropTypes.string,
  firstRowAlign: PropTypes.string,
  rowAlign: PropTypes.string,
};

export default SmxTable;
