import React, { useEffect } from "react";
import SecondaryPageContainer from "components/SecondaryPageContainer/NewSecondaryPageContainer";
import { createThemeLink } from "services/FontTheme";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import * as common from "common";
import ReactGA from "react-ga4";
import PropTypes from "prop-types";
import { useSelector, useDispatch } from "react-redux";
import { selectTheme } from "stateMgmt/complianceSlice";
import { updateNavLink } from "stateMgmt/daaSlice";

const S3Path = common.getS3Url();

const EvaluationsPage = (props) => {
  const dispatch = useDispatch();
  const theme = useSelector(selectTheme);

  ReactGA.send({
    hitType: "pageview",
    page: "/components/MainContentArea/EvaluationsPage/EvaluationsPage.js",
    title: "Evaluations",
  });

  const leavePage = () => {
    dispatch(updateNavLink({ calllingLink: "EVAL" }));
  };

  useEffect(() => {
    document.getElementById("page-heading").focus();
  });

  const evalutationsDownloadsByYear = {
    2014: `${S3Path}evaluations/PEPFAR.Evaluation.Titles.and.Public.Dissemination.Spreadsheet.-.2014.52120cb0.pdf`, // + EVALUATIONS_2014,
    2015: `${S3Path}evaluations/PEPFAR.Evaluation.Titles.and.Public.Dissemination.Spreadsheet.-.2015.ec253385.pdf`, // + EVALUATIONS_2015,
    2016: `${S3Path}evaluations/FY16_Eval_F_DataCall_FINAL.pdf`,
    2017: `${S3Path}evaluations/FY17_Eval_F_DataCall_FINAL.pdf`,
    2018: `${S3Path}evaluations/FY18_Eval_F_DataCall_FINAL.pdf`,
    2019: `${S3Path}evaluations/FY19_Eval_F_DataCall_FINAL.pdf`,
    2020: `${S3Path}evaluations/FY20_Eval_F_DataCall_FINAL.pdf`,
    2021: `${S3Path}evaluations/FY21_F_DataCall_Spotlight.pdf`,
    2022: `${S3Path}evaluations/FY22_F_DataCall_Spotlight.pdf`,
    2023: `${S3Path}evaluations/FY23_F_DataCall_Spotlight.pdf`,
  };

  const linkListLength = Object.keys(evalutationsDownloadsByYear).length;

  const pageContents = (
    <div id="Evaluations_page-top" role="heading">
      <div id="page-heading" role="heading">
        <Typography variant="h1" role="heading" tabIndex={0}>
          PEPFAR Evaluations
        </Typography>
        <Typography>
          <p tabIndex={0}>
            The PEPFAR Evaluation Standards of Practice (ESoP), first published
            in 2014, was developed in response to the growing need to maximize
            the utility of evaluations and to strengthen evaluation quality and
            transparency. The current version, ESoP v3.1, maintains the original
            11 standards, refines PEPFAR evaluation classification, and provides
            updated guidance on reporting requirements and processes.
          </p>
          <p tabIndex={0}>
            The PEPFAR approach to dissemination of evaluation results is
            two-fold: internal dissemination within the PEPFAR community, and
            external, public release of results (post-embargo period, as
            applicable). The accompanying tables provide descriptions of
            PEPFAR-funded evaluations completed between 2014 and the most recent
            reporting year. Where available, these tables also include URLs
            where the public can access evaluation findings. This information is
            updated annually—as the most recent inventories of evaluations are
            made public—to facilitate access to and use of evaluation findings
            for program planning and decision-making across PEFPAR and
            PEPFAR-funded countries.
          </p>
          <p tabIndex={0}>
            PEPFAR is committed to implementing robust program monitoring to
            track progress toward reaching epidemic control. In addition to
            routine program data, high quality evaluations that answer key
            questions driven by evidence gaps, are implemented in line with
            quality standards, and for which results are disseminated publicly
            and used by relevant stakeholders are critical to achieve the
            95-95-95 goals and reach epidemic control.
          </p>
        </Typography>
      </div>

      <Box sx={{ color: theme.linkColor }}>
        {Object.keys(evalutationsDownloadsByYear)
          .reverse()
          .map((objectAttribute, objectAttributeIndex) =>
            createThemeLink(
              evalutationsDownloadsByYear[objectAttribute],
              `Download PDF of PEPFAR Evaluations ${objectAttribute}`,
              objectAttributeIndex === linkListLength - 1
                ? () => leavePage()
                : null
            )
          )}
      </Box>
    </div>
  );

  return (
    <SecondaryPageContainer
      contents={pageContents}
      showToc={false}
      pageHeaderId="page-heading"
      isSidebarCollapsed={props.isSidebarCollapsed}
    />
  );
};

EvaluationsPage.propTypes = {
  isSidebarCollapsed: PropTypes.bool.isRequired,
};
export default EvaluationsPage;
