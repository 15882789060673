import { createSlice } from "@reduxjs/toolkit";

export const dark = {
  backgroundColor: "black",
  secondaryColor: "#212121",
  bannerColor: "#1a2439",
  color: "#ffffff",
  linkColor: "#58a6ff",
  tableHeaderColor: "#000000",
  tableHeaderFontColor: "#ffffff",
  tableFirstRowColor: "#2e2e2e",
  tableEvenColor: "#5c5c5c",
  tableOddColor: "#454545",
  warningColor: "#FF0000",
  liteFontColor: "#ffffff",
  headerBorder: "3px solid #ffffff",
  bgGradient:
    "rgb(107,133,235) linear-gradient(0deg, rgba(107,133,235,0.8) 25%, rgba(5,17,140,1) 100%)",
};

export const light = {
  backgroundColor: "white",
  secondaryColor: "#EEEEEE",
  bannerColor: "#1a2439",
  color: "#000000",
  linkColor: "#0000FF",
  tableHeaderColor: "#18477D",
  tableHeaderFontColor: "#ffffff",
  tableFirstRowColor: "#919191",
  tableEvenColor: "#e6e6e6",
  tableOddColor: "#c9c9c9",
  warningColor: "#be0000",
  liteFontColor: "#596067",
  headerBorder: "6px solid #be0000",
  bgGradient: "white",
};

const isDarkMode = localStorage.getItem("dark") === "true";

const initialState = {
  context: "home",
  theme: isDarkMode ? { ...dark } : { ...light },
};

export const complianceSlice = createSlice({
  name: "complianceState",
  initialState,
  reducers: {
    updateComplianceState: (state, action) => {
      const newState = {
        ...state,
        ...action.payload,
      };
      return newState;
    },
    toggleTheme: (state) => {
      const isDark = localStorage.getItem("dark") === "true";
      const newMode = isDark ? { ...light } : { ...dark };
      localStorage.setItem("dark", JSON.stringify(!isDark));
      const newState = {
        ...state,
        theme: { ...newMode },
      };
      return newState;
    },
  },
});

export const selectCompliance = (state) => state.complianceState;
export const selectTheme = (state) => state.complianceState.theme;
export const selectContext = (state) => state.complianceState.context;
export const { updateComplianceState, toggleTheme } = complianceSlice.actions;
export default complianceSlice.reducer;
